// import { Typography } from '@material-ui/core'
import React from 'react'
import TitleWithBreadcrumbRow from '@app/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import * as S from './styles'
import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { SEO } from '../legacy/mol.seo/seo.component'
import { StaticQuery, graphql } from 'gatsby'
import InfoContato from '@root/src/components/InfoContato'
import Title from '../Title'

import { Button } from 'atomic'

const LandingPageComponent = ({
  slug,
  titulo,
  descricao,
  imagem,
  sessao_1,
  sessao_2,
  sessao_3,
  sessao_3_topics,
  sessao_contato_1,
  sessao_contato_2,
  call_to_action,
  informacoesContato
}: any) => {
  const theme = useTheme()
  const downOfSm = useMediaQuery(theme.breakpoints.down('xs'))

  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[`/${slug}`] = titulo

  return (
    <S.Wrapper>
      <StaticQuery
        query={graphql`
          {
            seoGeral: cosmicjsInformacoesEstaticas(slug: { eq: "seo-geral" }) {
              metadata {
                nomeDaMarca
              }
            }
          }
        `}
        render={staticData => (
          <SEO
            socialMedia={{
              image: '',
              canonicalUrl: '',
              title: titulo,
              description: 'dddescription'
            }}
          />
        )}
      />
      <Container maxWidth="md">
        <TitleWithBreadcrumbRow addtionalDictionary={breadcrumbAdditionalDictionary} />
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Title variant="h5">{titulo}</Title>
                <S.Description>{descricao}</S.Description>
              </Grid>
              <Grid item xs={12} sm={6}>
                <S.ImageContainer>
                  <S.ImagePrincipal src={imagem.imgix_url} />
                </S.ImageContainer>
              </Grid>
            </Grid>
          </Grid>
          {sessao_1.aparecer_site && (
            <Grid item xs={12}>
              <S.CardsContainer>
                <div className="left">
                  <img src={sessao_1.cards[0].imagem.imgix_url} />
                  <span>{sessao_1.cards[0].descricao}</span>
                </div>
                <div className="middle">
                  <img src={sessao_1.cards[1].imagem.imgix_url} />
                  <span>{sessao_1.cards[1].descricao}</span>
                </div>
                <div className="right">
                  <img src={sessao_1.cards[2].imagem.imgix_url} />
                  <span>{sessao_1.cards[2].descricao}</span>
                </div>
              </S.CardsContainer>
            </Grid>
          )}
          {sessao_2.aparecer_site && (
            <Grid item xs={12}>
              <S.Text dangerouslySetInnerHTML={{ __html: sessao_2.texto }} />
            </Grid>
          )}
          {sessao_3.aparecer_site && (
            <Grid item xs={12}>
              <Title variant="h6">{sessao_3.titulo}</Title>
              <S.Text dangerouslySetInnerHTML={{ __html: sessao_3.texto }} />
            </Grid>
          )}
          {sessao_3_topics.aparecer_site && (
            <Grid item xs={12}>
              <Title variant="h6">{sessao_3_topics.titulo}</Title>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <S.TopicsContainer>
                    <ul>
                      {sessao_3_topics.topics
                        .slice(0, sessao_3_topics.topics.length / 2)
                        .map(topico => (
                          <li>{topico.topic_name}</li>
                        ))}
                    </ul>
                  </S.TopicsContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <S.TopicsContainer className="last">
                    <ul>
                      {sessao_3_topics.topics
                        .slice(sessao_3_topics.topics.length / 2, sessao_3_topics.topics.length)
                        .map(topico => (
                          <li>{topico.topic_name}</li>
                        ))}
                    </ul>
                  </S.TopicsContainer>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container>
              {sessao_contato_1.aparecer_site && (
                <>
                  <Grid item xs={12} sm={5}>
                    <S.InfoContato1>
                      <Title variant="h7" noLine>
                        {sessao_contato_1.titulo}
                      </Title>
                      <div dangerouslySetInnerHTML={{ __html: sessao_contato_1.texto }} />
                    </S.InfoContato1>
                  </Grid>
                  {!downOfSm && (
                    <Grid item sm={1}>
                      <S.VerticalSeparator />
                    </Grid>
                  )}
                </>
              )}

              {sessao_contato_2.aparecer_site && (
                <Grid item xs={12} sm={6}>
                  <S.InfoContato2>
                    <InfoContato
                      informacoesContato={informacoesContato}
                      horarios={sessao_contato_2.horarios}
                      subtitulo={sessao_contato_2.subtitlulo}
                      titulo={sessao_contato_2.titulo}
                    />
                  </S.InfoContato2>
                </Grid>
              )}
            </Grid>
          </Grid>

          {call_to_action.aparecer_site && (
            <Grid item xs={12}>
              <S.ButtonContainer>
                <div>
                  <Button
                    id={`button-submit-medic-register`}
                    expanded
                    to={call_to_action.call_to_action_link}
                    external={
                      call_to_action.call_to_action_link.substring(0, 4) === 'http' ? true : false
                    }
                    kind="primary"
                  >
                    {call_to_action.call_to_action_titulo}
                  </Button>
                </div>
              </S.ButtonContainer>
            </Grid>
          )}
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default LandingPageComponent
