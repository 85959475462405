// import { Typography } from '@material-ui/core'
import React from 'react'

import iconAtendente from '@root/src/assets/img/iconAtendente.svg'
import iconsWhatsapp from '@root/src/assets/img/iconsWhatsapp.svg'
import iconTelefone from '@root/src/assets/img/iconTelefone.svg'

import * as S from './styles'
import { Grid } from '@material-ui/core'

const InfoContato = ({ titulo, subtitulo, horarios, informacoesContato }: any) => {
  return (
    <S.Wrapper>
      <S.UnitsInfoTopPageContactContainer>
        <Grid container>
          <Grid item xs={12}>
            <S.UnitsInfoTopPageContactTitle>{titulo}</S.UnitsInfoTopPageContactTitle>
          </Grid>

          <Grid item xs={12}>
            <S.UnitsInfoTopPage>{subtitulo}</S.UnitsInfoTopPage>
          </Grid>

          <Grid item xs={12}>
            <S.ContactContainer>
              <div>
                <img src={iconAtendente} />
              </div>

              <S.WhatsappPhoneContainer>
                <S.LinkToContact
                  href={`tel:${informacoesContato.telefoneContato}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={iconTelefone} />
                  <span>{informacoesContato.label_telefone}</span>
                </S.LinkToContact>
                <S.LinkToContact
                  href={informacoesContato.agendamentoWhatsappLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={iconsWhatsapp} />
                  <span>{informacoesContato.label_whatsapp}</span>
                  <p>(Whatsapp)</p>
                </S.LinkToContact>
              </S.WhatsappPhoneContainer>
            </S.ContactContainer>
          </Grid>
          <Grid item xs={12}>
            <S.UnitsInfoTopPage>{horarios}</S.UnitsInfoTopPage>
          </Grid>
        </Grid>
      </S.UnitsInfoTopPageContactContainer>
    </S.Wrapper>
  )
}

export default InfoContato
