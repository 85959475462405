import styled from 'styled-components'

export const Wrapper = styled.div``

export const UnitsInfoTopPageContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px 0;
`

export const UnitsInfoTopPageContactTitle = styled.div`
  font-family: Signika;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #373a3a;
`
export const ContactContainer = styled.div`
  display: flex;
  margin: 10px 0;
  font-family: Signika;
`

export const WhatsappPhoneContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
`

export const LinkToContact = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
  span {
    margin-left: 5px;
    text-decoration: underline;
  }
  p {
    margin-left: 5px;
    font-style: normal;

    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.color.primary};
  }
`

export const UnitsInfoTopPage = styled.span`
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #444444 !important;

  p {
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #444444 !important;
  }
`
