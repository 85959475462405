import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { PageProps } from '@app/utils/local-types'
import { getClientAndDoctorUrl } from 'utils/url'

import { Query } from '@root/src/data/graphql/graphql-types'
import { graphql } from 'gatsby'
import React from 'react'

import LandingPageComponent from '@root/src/components/LandingPageComponent'

class LPsTemplate extends React.Component<PageProps<any, Query>> {
  render() {
    const informacoesContato = this.props.data.informacoesContato.metadata
    const data = this.props.data.cosmicjsLps
    const {
      titulo,
      descricao,
      imagem,
      sessao_1,
      sessao_2,
      sessao_3,
      sessao_3_topics,
      sessao_contato_1,
      sessao_contato_2,
      call_to_action
    } = data.metadata

    const [clientPath, doctorPath] = getClientAndDoctorUrl(this.props.location)

    return (
      <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
        <IndexLayout location={this.props.location}>
          <LandingPageComponent
            slug={data.slug}
            titulo={titulo}
            descricao={descricao}
            imagem={imagem}
            sessao_1={sessao_1}
            sessao_2={sessao_2}
            sessao_3={sessao_3}
            sessao_3_topics={sessao_3_topics}
            sessao_contato_1={sessao_contato_1}
            sessao_contato_2={sessao_contato_2}
            call_to_action={call_to_action}
            informacoesContato={informacoesContato}
          />
        </IndexLayout>
      </ClientDoctorSelectContext.Provider>
    )
  }
}

export default LPsTemplate

export const query = graphql`
  query LpDetailsBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    informacoesContato: cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
      metadata {
        telefoneContato
        chatOnlineLink
        agendamentoWhatsappLink
        telefoneContatoMedico
        label_telefone
        label_whatsapp
        chatOnlineLinkMedico
        agendamentoWhatsappLinkMedico
        texto_fale_conosco
        show_whatsapp_contactus
      }
    }

    cosmicjsLps(slug: { eq: $slug }) {
      slug
      title
      metadata {
        titulo
        descricao
        imagem {
          url
          imgix_url
        }
        sessao_1 {
          cards {
            descricao
            imagem {
              url
              imgix_url
            }
          }
          aparecer_site
        }
        sessao_2 {
          aparecer_site
          texto
        }
        sessao_3 {
          titulo
          aparecer_site
          texto
        }
        sessao_3_topics {
          topics {
            topic_name
          }
          aparecer_site
          titulo
        }
        sessao_contato_1 {
          aparecer_site
          texto
          titulo
        }
        sessao_contato_2 {
          aparecer_site
          titulo
          subtitlulo
          horarios
        }
        call_to_action {
          call_to_action_titulo
          call_to_action_link
          aparecer_site
        }
      }
    }
  }
`
