import styled from 'styled-components'

export const Wrapper = styled.div``

export const VerticalSeparator = styled.div`
  margin: 36px;
  width: 0.28px;
  height: 188px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #b3dce0;
  border-radius: 3px;
`

export const Description = styled.span`
  margin-top: 15px;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
`

export const Text = styled.div`
  margin-top: 25px;
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin-bottom: 25px;
`

export const ImageContainer = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ImagePrincipal = styled.img`
  width: 350px;
  height: 200px;
  object-fit: cover;

  @media (max-width: 425px) {
    width: 100%;
  }
`

export const InfoContato1 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;

  div {
    width: 100%;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  margin-bottom: 50px;
  div {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`

export const InfoContato2 = styled.div`
  margin: 20px;
  @media (max-width: 768px) {
    margin: 0;
  }
`

export const TopicsContainer = styled.div`
  ul {
    padding-left: 20px;

    @media (max-width: 425px) {
      margin: 0px;
    }

    li {
      font-family: Signika;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
    }
  }

  &.last {
    margin-bottom: 25px;
  }
`

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 25px;

  div {
    height: 156px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b3dce0;
    padding: 15px;

    img {
      width: 50px;
    }

    span {
      font-family: Signika;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #373a3a;
      margin: 0px 16px;
    }

    &.middle {
      background-color: #e6f3f5;
    }

    &.left {
      border-radius: 16px 0px 0px 16px;
    }

    &.right {
      border-radius: 0px 16px 16px 0px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    div {
      border-radius: 0px !important;
      height: 90px;
      padding: 5px;
      justify-content: flex-start;
      padding-left: 35px;
    }
  }
`
